@import '../colours';
@import '../globals';

.default {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.img {  
  border-radius: 10px;
  background-color: $primaryDark;
  box-sizing: border-box;
}

.label {
  @include text;
  border-radius: 10px;
  background-color: $primaryOrange;
  color: #ffffff;
  text-align: center;
  box-sizing: border-box;
}